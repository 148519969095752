<template>
  <div>
    <v-simple-table>
      <tr>
        <th>SDN Entry ID</th>
      </tr>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="col in columns" :key="col.key">{{ col.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.sdnEntryId">
            <th v-for="col in columns" :key="col.key">{{ row[col.key] || "-" }}</th>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "AmlList",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          title: "SDN Entry Id",
          key: "sdnEntryId",
        },
        { title: "Address", key: "address" },
        { title: "Country", key: "country" },
        { title: "Date of Birth", key: "dob" },
        { title: "Listed By", key: "listedBy" },
        { title: "Matching Percentage", key: "matchingPercentage" },
        { title: "Name", key: "name" },
        { title: "Type", key: "ptype" },
        { title: "SDN Type", key: "sdnType" },
      ],
    };
  },
};
</script>

<style scoped></style>
