<template>
  <v-list-item>
    <v-list-item-subtitle class="item-label"> {{ title }} </v-list-item-subtitle>
    <v-list-item-title>
      <div v-if="type === 'photo'" class="item-value">
        <img alt="nid-back-image" :src="`data:image/jpg;base64,${value}`" />
      </div>
      <div v-else-if="type === 'bool'" class="item-value">
        {{ value ? "Yes" : "No" }}
      </div>
      <div v-else-if="type === 'date'" class="item-value">
        {{ value ? formatDate(value) : "-" }}
      </div>
      <div v-else class="item-value">{{ value || "-" }}</div>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "Row",
  props: {
    type: {
      type: String,
      default: () => "string",
    },
    title: {
      type: String,
      default: () => "---",
    },
    value: {
      default: () => "-",
    },
  },
};
</script>

<style scoped></style>
