<template>
  <v-container id="onboarding-details">
    <v-row justify="center" class="mt-3">
      <v-col cols="12" class="text-center">
        <h4 class="text-h4 font-weight-regular mb-4">Onboarding Details (CBS)</h4>
        <v-btn style="width: 150px" @click="() => $router.back()" outlined>
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>
        <v-btn style="width: 150px" @click="showGenerateModal = true" class="ml-5" outlined>
          <v-icon>mdi-download-box-outline</v-icon> Download
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card :loading="loading" v-if="registration">
          <v-expansion-panels multiple :value="[0]">
            <v-expansion-panel>
              <v-expansion-panel-header> General Information</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-expansion-panel-content>
                    <Row
                      v-for="row in Object.keys(rows.general)"
                      :key="row"
                      :title="rows.general[row].title"
                      :value="registration[row]"
                      :type="rows.general[row].type"
                    ></Row>
                  </v-expansion-panel-content>
                  <v-list-item v-if="canShowUserInfo()">
                    <v-list-item-subtitle class="item-label"> User</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-btn
                        outlined
                        :to="{ name: 'user.view', params: { id: registration.userId } }"
                      >
                        {{ registration.userId || "-" }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Personal Information</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Row
                  v-for="row in Object.keys(rows.personal)"
                  :key="row"
                  :title="rows.personal[row].title"
                  :value="registration[row]"
                  :type="rows.personal[row].type"
                ></Row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Identity Information</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item v-if="canShowNidOcrCard()">
                  <v-list-item-subtitle class="item-label">OCR Request ID</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-btn
                      outlined
                      :to="{
                        name: 'nid-ocr-card.single',
                        params: { requestId: registration.ocrRequestId },
                      }"
                    >
                      {{ registration.ocrRequestId || "-" }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="canShowLivenessRequest()">
                  <v-list-item-subtitle class="item-label"
                    >Liveness Request ID
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-btn
                      outlined
                      :to="{
                        name: 'liveness-request.single',
                        params: { requestId: registration.livenessRequestId },
                      }"
                    >
                      {{ registration.livenessRequestId || "-" }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="canShowMatchRequest()">
                  <v-list-item-subtitle class="item-label">Match Request ID</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-btn
                      outlined
                      :to="{
                        name: 'match-request.single',
                        params: { requestId: registration.matchRequestId },
                      }"
                    >
                      {{ registration.matchRequestId || "-" }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-subtitle class="item-label">AML Request ID</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ registration.amlReferenceNo || "-" }}
                  </v-list-item-title>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> NID Information</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item class="ma-0 pa-0" v-if="compareData">
                  <v-data-table
                    class="my-5 compare-table"
                    elevation="10"
                    :headers="compareDataHeaders"
                    :items="compareData"
                    disable-sort
                    disable-filtering
                    disable-pagination
                    hide-default-footer
                  />
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Customer Location</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Row
                  v-for="row in Object.keys(rows.location)"
                  :key="row"
                  :title="rows.location[row].title"
                  :value="registration[row]"
                  :type="rows.location[row].type"
                ></Row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Nominee Information</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Row
                  v-for="row in Object.keys(rows.nominee)"
                  :key="row"
                  :title="rows.nominee[row].title"
                  :value="registration[row]"
                  :type="rows.nominee[row].type"
                ></Row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Service Information</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Row
                  v-for="row in Object.keys(rows.service)"
                  :key="row"
                  :title="rows.service[row].title"
                  :value="registration[row]"
                  :type="rows.service[row].type"
                ></Row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> CBS Customer & Account</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Row
                  v-for="row in Object.keys(rows.cbs)"
                  :key="row"
                  :title="rows.cbs[row].title"
                  :value="registration[row]"
                  :type="rows.cbs[row].type"
                ></Row>
                <template v-if="registration['CBS Customer and Account']">
                  <Row
                    title="Create Account Response Code"
                    :value="
                      registration['CBS Customer and Account'].createAccountResponse.responseCode
                    "
                  ></Row>
                  <Row
                    title="Create Account Response Description"
                    :value="
                      registration['CBS Customer and Account'].createAccountResponse
                        .responseDescription
                    "
                  ></Row>
                  <Row
                    title="Create Account Response String"
                    :value="
                      registration['CBS Customer and Account'].createAccountResponse.responseString
                    "
                  ></Row>
                  <Row
                    title="Create Customer Response Code"
                    :value="
                      registration['CBS Customer and Account'].createCustomerResponse.responseCode
                    "
                  ></Row>
                  <Row
                    title="Create Customer Response Description"
                    :value="
                      registration['CBS Customer and Account'].createCustomerResponse
                        .responseDescription
                    "
                  ></Row>
                  <Row
                    title="Create Customer Response String"
                    :value="
                      registration['CBS Customer and Account'].createCustomerResponse.responseString
                    "
                  ></Row>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> AML Sanction Screening</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Row
                  v-for="row in Object.keys(rows.aml)"
                  :key="row"
                  :title="rows.aml[row].title"
                  :value="registration[row]"
                  :type="rows.aml[row].type"
                ></Row>
                <h5
                  v-if="
                    registration &&
                    registration.sdnEntryList &&
                    registration.sdnEntryList.length > 0
                  "
                  class="text-center my-5"
                >
                  SDN Entry List
                </h5>
                <AmlList
                  v-if="
                    registration &&
                    registration.sdnEntryList &&
                    registration.sdnEntryList.length > 0
                  "
                  :rows="registration.sdnEntryList"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-card class="text-center grey--text py-15" v-else>
          <v-progress-circular indeterminate size="44" />
          <div class="caption my-2">Loading onboarding details...</div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="registration && registration.userId"
      v-model="showGenerateModal"
      max-width="600px"
      scrollable
    >
      <!--      Here nested v-if used, because the element should be recreated everytime
to reset components internal data. Otherwise multiple clicks can result to infinite loading -->
      <generate-report-single
        v-if="showGenerateModal"
        :user-id="registration.userId"
        @close="showGenerateModal = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { NID_OCR_CARD_SINGLE, NID_SCRAPER_INFO, ONBOARDING_CBS_GET_BY_UID } from "@/constants/api";
import GenerateReportSingle from "@/views/pages/dashboard/cbs/GenerateReportSingle";
import Row from "@/views/pages/dashboard/cbs/Row";
import AmlList from "@/views/pages/dashboard/cbs/AmlList";

export default {
  name: "CBSView",
  metaInfo: { title: "Onboarding Details (CBS)" },
  components: { GenerateReportSingle, Row, AmlList },
  data: () => ({
    loading: false,
    registration: null,
    nidData: null,
    ocrParsedData: null,
    compareData: null,
    csvFileName: "nothing.csv",
    downloadableData: [],
    showGenerateModal: false,
    rows: {
      general: {
        id: { type: "string", title: "Id" },
        type: { type: "string", title: "Type" },
        sessionId: { type: "string", title: "Session Id" },
        status: { type: "string", title: "Status" },
        statusMessage: { type: "string", title: "Status Message" },
        phoneNumber: { type: "string", title: "Phone Number" },
        operatorCode: { type: "string", title: "Operator Code" },
        emailAddress: { type: "string", title: "Email Address" },
        updatedAt: { type: "date", title: "Updated At" },
        createdAt: { type: "date", title: "Created At" },
      },
      personal: {
        photoThumbnailB64: { type: "photo", title: "Photo" },
        applicantName: { type: "string", title: "Applicant Name" },
        fatherBn: { type: "string", title: "Father Bn" },
        fatherEn: { type: "string", title: "Father En" },
        acceptFatherSpelling: { type: "bool", title: "Accept Father Spelling" },
        profession: { type: "string", title: "Profession" },
        motherBn: { type: "string", title: "Mother Bn" },
        motherEn: { type: "string", title: "Mother En" },
        acceptMotherSpelling: { type: "bool", title: "Accept Mother Spelling" },
        spouseBn: { type: "string", title: "Spouse Bn" },
        spouseEn: { type: "string", title: "Spouse En" },
        acceptSpouseSpelling: { type: "bool", title: "Accept Spouse Spelling" },
        firstName: { type: "string", title: "First Name" },
        middleName: { type: "string", title: "Middle Name" },
        lastName: { type: "string", title: "Last Name" },
        surName: { type: "string", title: "Sur Name" },
        gender: { type: "string", title: "Gender" },
        bloodGroup: { type: "string", title: "Blood Group" },
        signatureB64: { type: "photo", title: "Signature" },
      },
      location: {
        nationality: { type: "string", title: "Nationality" },
        placeOfBirth: { type: "string", title: "Place Of Birth" },
        permanentAddressBn: { type: "string", title: "Permanent Address Bn" },
        permanentAddressEn: { type: "string", title: "Permanent Address En" },
        acceptPermanentAddressSpelling: {
          type: "bool",
          title: "Accept Permanent Address Spelling",
        },
        presentAddressBn: { type: "string", title: "Present Address Bn" },
        presentAddressEn: { type: "string", title: "Present Address En" },
        acceptPresentAddressSpelling: { type: "bool", title: "Accept Present Address Spelling" },
        businessAddress: { type: "string", title: "Business Address" },
        businessAddressDistrict: { type: "string", title: "Business Address District" },
        businessAddressDivision: { type: "string", title: "Business Address Division" },
        businessAddressPostCode: { type: "string", title: "Business Address Post Code" },
        businessAddressPostOffice: { type: "string", title: "Business Address Post Office" },
        businessAddressThana: { type: "string", title: "Business Address Thana" },
        businessSegmentCode: { type: "string", title: "Business Segment Code" },
        contactAddress: { type: "string", title: "Contact Address" },
        contactAddressDistrict: { type: "string", title: "Contact Address District" },
        contactAddressDivision: { type: "string", title: "Contact Address Division" },
        contactAddressPostCode: { type: "string", title: "Contact Address Post Code" },
        contactAddressPostOffice: { type: "string", title: "Contact Address Post Office" },
        contactAddressThana: { type: "string", title: "Contact Address Thana" },
        contactAddressType: { type: "string", title: "Contact Address Type" },
        emergencyAddress: { type: "string", title: "Emergency Address" },
        emergencyAddressDistrict: { type: "string", title: "Emergency Address District" },
        emergencyAddressDivision: { type: "string", title: "Emergency Address Division" },
        emergencyAddressPostCode: { type: "string", title: "Emergency Address Post Code" },
        emergencyAddressPostOffice: { type: "string", title: "Emergency Address Post Office" },
        emergencyAddressThana: { type: "string", title: "Emergency Address Thana" },
        emergencyAddressType: { type: "string", title: "Emergency Address Type" },
        emergencyContactEmail: { type: "string", title: "Emergency Contact Email" },
        emergencyContactMobile: { type: "string", title: "Emergency Contact Mobile" },
        emergencyContactName: { type: "string", title: "Emergency Contact Name" },
      },
      nominee: {
        entitledPercentage: { type: "string", title: "Entitled Percentage" },
        nomineeAddress: { type: "string", title: "Nominee Address" },
        nomineeAddressDistrict: { type: "string", title: "Nominee Address District" },
        nomineeAddressDivision: { type: "string", title: "Nominee Address Division" },
        nomineeAddressPostCode: { type: "string", title: "Nominee Address Post Code" },
        nomineeAddressPostOffice: { type: "string", title: "Nominee Address Post Office" },
        nomineeAddressThana: { type: "string", title: "Nominee Address Thana" },
        nomineeAddressType: { type: "string", title: "Nominee Address Type" },
        nomineeDob: { type: "string", title: "Nominee Dob" },
        nomineeId: { type: "string", title: "Nominee Id" },
        nomineeIdImageB64: { type: "photo", title: "Nominee Id Image" },
        nomineeIdType: { type: "string", title: "Nominee Id Type" },
        nomineeName: { type: "string", title: "Nominee Name" },
        nomineeNidBackB64: { type: "photo", title: "Nominee Nid Back" },
        nomineeNidFrontB64: { type: "photo", title: "Nominee Nid Front" },
        nomineePhotoB64: { type: "photo", title: "Nominee Photo" },
        nomineeRelationship: { type: "string", title: "Nominee Relationship" },
      },
      service: {
        serviceType: { type: "string", title: "Service Type" },
        accountNature: { type: "string", title: "Account Nature" },
        transactionAmountType: { type: "string", title: "Transaction Amount Type" },
        initialDeposit: { type: "string", title: "Initial Deposit" },
        monthlyIncome: { type: "string", title: "Monthly Income" },
        documentType: { type: "string", title: "Document Type" },
        sourceOfIncome: { type: "string", title: "Source Of Income" },
        bankingMode: { type: "string", title: "Banking Mode" },
        productCode: { type: "string", title: "Product Code" },
        accountType: { type: "string", title: "Account Type" },
        accountCurrency: { type: "string", title: "Account Currency" },
        branchCode: { type: "string", title: "Branch Code" },
        branchDistrict: { type: "string", title: "Branch District" },
        branchDivision: { type: "string", title: "Branch Division" },
        customerCategoryCode: { type: "string", title: "Customer Category Code" },
        chequeBook: { type: "bool", title: "Cheque Book" },
        debitCard: { type: "bool", title: "Debit Card" },
        okWallet: { type: "bool", title: "Ok Wallet" },
        digitalBanking: { type: "bool", title: "Digital Banking" },
        includeReferenceNo: { type: "bool", title: "Include Reference No" },
        referenceNumber: { type: "string", title: "Reference Number" },
        residenceStatus: { type: "string", title: "Residence Status" },
        usCitizen: { type: "bool", title: "Us Citizen" },
        usGreenCardHolder: { type: "bool", title: "Us Green Card Holder" },
        usResident: { type: "bool", title: "Us Resident" },
        sectorCode: { type: "string", title: "Sector Code" },
        socialSecurityNo: { type: "string", title: "Social Security No" },
        taxIdentificationNo: { type: "string", title: "Tax Identification No" },
        etinNumber: { type: "string", title: "Etin Number" },
      },
      aml: {
        amlMatched: { type: "bool", title: "Aml Matched" },
        amlReferenceNo: { type: "string", title: "Aml Reference No" },
        review: { type: "string", title: "Review" },
        reviewedBy: { type: "string", title: "Reviewed By" },
      },
      cbs: {
        customerId: { type: "string", title: "Customer Id" },
        accountNo: { type: "string", title: "Account No" },
        accountCode: { type: "string", title: "Account Code" },
        accountDescription: { type: "string", title: "Account Description" },
        accountOpenDate: { type: "date", title: "Account Open Date" },
        atmEnabledAccount: { type: "bool", title: "Atm Enabled Account" },
        chequeBookAccount: { type: "bool", title: "Cheque Book Account" },
        dormantAccount: { type: "bool", title: "Dormant Account" },
        frozenAccount: { type: "bool", title: "Frozen Account" },
        noCreditAccount: { type: "bool", title: "No Credit Account" },
        noDebitAccount: { type: "bool", title: "No Debit Account" },
      },
    },
  }),
  computed: {
    onboardId() {
      return Number(this.$route.params.id);
    },
    compareDataHeaders: () => [
      { text: "Field", value: "fieldName" },
      { text: "OCR Data", value: "ocrData" },
      { text: "NID Data", value: "nidData" },
      { text: "Edited Data", value: "finalData" },
      { text: "Matching %", value: "matching" },
    ],
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchRegistration();
      } catch (err) {
        console.error(err);
        this.$iziToast.showError(err);
        this.$router.push({ name: "cbs.index" });
      }
      try {
        // do not go to back page if the following calls failed
        await this.fetchNidData();
        await this.fetchOcrParsedData();
        await this.constructCompareData();
      } catch (e) {
        //
      }
      await this.$nextTick();
      this.loading = false;
    },
    async fetchRegistration() {
      const response = await this.$axios.get(`${ONBOARDING_CBS_GET_BY_UID}/${this.onboardId}`);
      if (response.data.code !== 0) {
        this.registration = {};
        throw new Error(response.data.message);
      }
      this.registration = response.data.data;
    },
    async fetchNidData() {
      let formData = new FormData();
      formData.append("nid", this.registration.nid);
      formData.append("dob", this.registration.dob);
      const response = await this.$axios.post(NID_SCRAPER_INFO, formData);

      if (response.data.code !== 0) {
        this.nidData = {};
        throw new Error(response.data.message);
      }
      this.nidData = this.emptyChecker(response.data.data, {});
    },
    async fetchOcrParsedData() {
      const response = await this.$axios.get(
        `${NID_OCR_CARD_SINGLE}?requestId=${this.registration.ocrRequestId}`
      );

      if (response.data.code !== 0) {
        this.ocrParsedData = {};
        throw new Error(response.data.message);
      }
      this.ocrParsedData = this.emptyChecker(response.data.data, {});
    },
    async constructCompareData() {
      this.compareData = [
        {
          fieldName: "Name",
          ocrData: this.emptyChecker(this.ocrParsedData.nameEn),
          nidData: this.emptyChecker(this.nidData.nameEn),
          finalData: this.emptyChecker(
            this.registration.firstName + " " + this.registration.lastName
          ),
          matching: this.registration.nameMatchScore,
        },
        {
          fieldName: "Father Name (EN)",
          ocrData: this.emptyChecker(this.ocrParsedData.fatherEn),
          nidData: this.emptyChecker(this.nidData.fatherNameEn),
          finalData: this.emptyChecker(this.registration.fatherEn),
          matching: this.registration.fatherNameMatchScore,
        },
        {
          fieldName: "Father Name (BN)",
          ocrData: this.emptyChecker(this.ocrParsedData.fatherBn),
          nidData: this.emptyChecker(this.nidData.fatherNameBn),
          finalData: this.emptyChecker(this.registration.fatherBn),
          matching: this.registration.fatherNameMatchScore,
        },
        {
          fieldName: "Mother Name (EN)",
          ocrData: this.emptyChecker(this.ocrParsedData.motherEn),
          nidData: this.emptyChecker(this.nidData.motherNameEn),
          finalData: this.emptyChecker(this.registration.motherEn),
          matching: this.registration.motherNameMatchScore,
        },
        {
          fieldName: "Mother Name (BN)",
          ocrData: this.emptyChecker(this.ocrParsedData.motherBn),
          nidData: this.emptyChecker(this.nidData.motherNameBn),
          finalData: this.emptyChecker(this.registration.motherBn),
          matching: this.registration.motherNameMatchScore,
        },
        {
          fieldName: "NID No",
          ocrData: this.emptyChecker(this.ocrParsedData.nid),
          nidData: this.emptyChecker(this.nidData.nid),
          finalData: this.emptyChecker(this.registration.nid),
        },
        {
          fieldName: "Date Of Birth",
          ocrData: this.emptyChecker(this.ocrParsedData.dob),
          nidData: this.emptyChecker(this.nidData.dateOfBirth),
          finalData: this.emptyChecker(this.registration.dob),
        },
        {
          fieldName: "Present Address",
          ocrData: this.emptyChecker(null),
          nidData: this.emptyChecker(this.nidData.presentAddressEn.addressLine),
          finalData: this.emptyChecker(this.registration.presentAddressEn),
        },
        {
          fieldName: "Permanent Address",
          ocrData: this.emptyChecker(null),
          nidData: this.emptyChecker(this.nidData.permanentAddressEn.addressLine),
          finalData: this.emptyChecker(this.registration.permanentAddressEn),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
#onboarding-details {
  .item-label {
    max-width: 225px;
    padding-right: 15px;
    text-align: right;
    white-space: pre-wrap;
  }

  .item-value {
    padding: 15px;
    white-space: pre-wrap;
    line-height: 24px;

    img {
      width: 100%;
      max-height: 200px;
      object-fit: contain;
      object-position: center left;
    }
  }

  .compare-table {
    width: 100%;
    padding: 0.5rem;
    margin: 0;
    border: 1px solid #c6c6c6;
  }

  .v-list-item {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
